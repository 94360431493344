@use './variables';

.header{
  position: sticky;
  top: 0;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: variables.$header-height;

  background-color: #6a0000;
  color: white;

  z-index: 100;
}


.logo {
  margin-left: 40px;
  font-size: 30px;
  font-weight: 600;
}

.main {
  position: relative;
  min-height: calc(100vh - #{variables.$header-height} - #{variables.$footer-height});
  margin-bottom: 50px;
}

.footer {
  position: fixed;
  width: 100%;
  height: 40px;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  line-height: 40px;
  margin-bottom: 15px;
  background-color: #fff5f5;
}